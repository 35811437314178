import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Informe from "../cuponesNo/Informe";
import ExcelUploader from "./ExcelUploader";

function Administracion() {
    const auth = useAuth();
    const navigate = useNavigate();
    const [sorteos, setSorteos] = useState([]);
    const [selectedSorteo, setSelectedSorteo] = useState("");
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        fetch("https://us-central1-inprojuy-f1173.cloudfunctions.net/app/sorteos")
            .then((response) => response.json())
            .then((data) => setSorteos(data))
            .catch((error) => console.error(error));

        const fetchUserRole = async () => {
            try {
                const response = await axios.get(
                    `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
                );
                setUserRole(response.data.role);
            } catch (error) {
                console.error("Error al obtener el rol del usuario:", error);
            }
        };
        fetchUserRole();
    }, [auth.id]);

    const handleSorteoChange = (event) => {
        setSelectedSorteo(event.target.value);
    };

    const logoutButton = () => {
        try {
            auth.logout();
        } catch (error) {
            console.log();
        }
    };

    return (
        <div className="">
            <center>
                <h1 className="botonbordeazul text-white px-5 mx-5 py-3 h1">
                    Panel de Control
                </h1>
            </center>

            {userRole === "admin" && (
                <div id="panel" style={{ border: '1px solid #000', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '5px' }}>

                    <p />
                    {/* <Informe /> */}
                    <button className="button11"
                        onClick={() => navigate('/slider')}
                    >
                        <div className=''>
                            Cargar Slider
                        </div>
                    </button>
                    <p />
                    {/* <button
                        onClick={() => navigate('/BDusuarios')}
                    >
                        <div className=''>
                            Base de Datos de Participantes
                        </div>
                    </button> */}

                  {/*   <button
                        onClick={() => navigate('/AddSorteo')}
                    >
                        <div className=''>
                            Agregar sorteo
                        </div>
                    </button>
                    <p />

                    <button
                        className=" "
                        onClick={() => navigate('/verganador')}
                    >
                        <div className=''>
                            Ver datos del Ganador del sorteo
                        </div>
                    </button>
                    <p />

                    <button

                        onClick={() => navigate('/ranking')}
                    >
                        <div className=''>
                            Ver Ranking de carga de cupones gral
                        </div>
                    </button>


                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <select value={selectedSorteo} onChange={handleSorteoChange}>
                            <option value="">Seleccione sorteo</option>
                            {sorteos.map((sorteo) => (
                                <option key={sorteo.n_sorte} value={sorteo.n_sorte}>
                                    {sorteo.n_sorte} - {sorteo.mes}
                                </option>
                            ))}
                        </select>
                        <div style={{ width: '10px' }} />
                        <button
                            disabled={!selectedSorteo}

                            onClick={() => navigate(`/rankingmes/${selectedSorteo}`)}
                        >
                            Ranking de carga de cupones por mes
                        </button>
                    </div> */}
                    <ExcelUploader></ExcelUploader>
                </div>
            )}

            <div>

            </div>

        </div>
    );
}

export default Administracion;
