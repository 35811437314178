import NavBar from './components/nav/NavBar';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Inicio from './paginas/inicio/Inicio';
import JuegoOnline from './paginas/JuegoOnline';
import JuegoResponsable from './paginas/JuegoResponsable';
import JuegoIlegal from './paginas/JuegoIlegal';
import Noticias from './paginas/Noticias';
import Footer from './components/Footer/Footer';
import InstitucionalMision from './paginas/InstitucionalMision';
import InstitucionalAutoridades from './paginas/InstitucionalAutoridades';
import InstitucionalDelegaciones from './paginas/InstitucionalDelegaciones';
import 'animate.css/animate.min.css'
import Agencias from './paginas/Agencias';
import Lavadodeactivos from './paginas/LavadoDeActivos';
import Certificados from './paginas/noticias/Certificados';
import Diplomatura from './paginas/noticias/Diplomatura';
import Enconstruccion from './paginas/Enconstruccion';
import Reglamentos from './paginas/Reglamentos';
import { AuthProvider } from './context/AuthContext';
import Login from './paginas/Login';
import Register from './paginas/Register';
import Reset from './paginas/Reset';
import LugaresdeAyuda from './paginas/LugaresdeAyuda';
import ComoTrabajamos from './paginas/ComoTrabajamos';
import Quetaljuegoyo from './paginas/Quetaljuegoyo';
import SitiosAutorizados from './paginas/SitiosAutorizados';
import Mitosyverdades from './paginas/Mitosyverdades';
import Recomendaciones from './paginas/Recomendaciones';
import DondeJuego from './paginas/DondeJuego';
import NoGanadores from './components/cuponesNo/NoGanadores';
import BDusuarios from './components/cuponesNo/BaseDados';
import cupones from './components/cuponesNo/Cupones';
import RankingCupones from './components/cuponesNo/RankingCupones';
import RankingCuponesMes from './components/cuponesNo/RankingCuponesMes';

import VerGanador from './components/cuponesNo/VerGanador';
import Browser from './components/browserConcurso/Browser';
import Formularios from './components/Formularios/Formularios';
import FormularioAuto from './components/Formularios/FormularioAuto';
import ProfileForm from './components/ProfileForm';
import Videounamas from './paginas/Videounamas';
import CargarSlider from './components/cargarSlider/CargarSlider';
import React, { useState, useEffect } from 'react';
import InproLoader from './components/loader/Loader';
import Wrapper from './components/loader/Wrapper';
import QuienesPuedenParticipar from './paginas/unaMas/QuienesPuedenParticipar';
import ComoCargarCupon from './paginas/unaMas/ComoCargarCupon';
import CuandoSortea from './paginas/unaMas/CuandoSortea';
import Premios from './paginas/unaMas/Premios';
import Denuncias from './paginas/Denuncias';
import InformeAistencia from './components/Formularios/InformeAsistencia';
import Ganadoresum from './paginas/unaMas/Ganadoresum';
import Concurso from './components/concurso/Concurso';
import GaleriaFotos from './paginas/unaMas/GaleriaFotos';
import Cupones from './components/cuponesNo/Cupones';
import CargarNoticia from './components/cargarNoticias/CargarNoticia';
import NoticiaDetail from './components/cargarNoticias/Noticias';
import Descargas from './paginas/descargas/Descargas';
import Calendariodejuegos from './paginas/Calendariodejuegos';
import AutoexcluidosLookup from './components/Formularios/AutoexcluidosLookup';
import AutoexcluidosGallery from './components/Formularios/AutoexcluidosGallery';
import ModificarAutoexcluido from './components/autoe/ModificarAutoexcluido';
import AddSorteoForm from './components/cuponesNo/AddSorteoForm';
import Dashboard from './components/dashboard/Dasboard';
import BDEmpleados from './components/dashboard/BDEmpleados';
import BDAutoexcluidos from './components/dashboard/BDAutoexcluidos';
import ConcursoRespEditForm from './components/dashboard/ConcursoRespEditForm';
import UserDocumentsGrid from './components/dashboard/concursovideosusuarios';
import YoutubeViewer from './components/dashboard/YoutubeViewer';
import ConcursoResppanel from './components/dashboard/DashConcurso';
import ConcursoResp from './components/dashboard/ConcursoRespo';
import Bases from './components/dashboard/concursovideos/bases';
import 'primereact/resources/themes/saga-blue/theme.css';  // or any other theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import RecomendacionesJuegoIlegal from './paginas/RecomendacionesJuegoIlegal';
import ManualProcedimientos from './paginas/lavadodeactivos/ManualProcedimientos';
import PersonasExpuestas from './paginas/lavadodeactivos/PersonasExpuestas';
import PorqueDeLaPrevencion from './paginas/lavadodeactivos/PorqueDeLaPrevencion';
import QueEsGafi from './paginas/lavadodeactivos/QueEsGafi';
import NormativaComplementaria from './paginas/lavadodeactivos/NormativaComplementaria';
import GuiaCobro from './paginas/lavadodeactivos/GuiaCobro';

import 'primereact/resources/themes/lara-light-blue/theme.css'; // o el tema que prefieras
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'; // para clases utilitarias (como flexbox, paddings, etc.)



function App() {
  const [loading, setLoading] = useState(true);

  const toggleLoading = () => {
    setLoading(!loading);
    console.log("loading", loading)

  };
  useEffect(() => {
    const handleLoad = () => {
      toggleLoading();
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  console.log("loading", loading)
  return (
    <AuthProvider>
      <div className="App">
        <Wrapper loading={loading}>
          <Router>
            <NavBar />
            <div className="margen ">
              <Routes >
                <Route path='/' element={<Inicio />} />
                <Route path='/juegoonline' element={<JuegoOnline />} />
                <Route path='/juegoResponsable' element={<JuegoResponsable />} />
                <Route path='/slider' element={<CargarSlider />} />
                <Route path='/browser' element={<Browser />} />
                {/*           <Route path='/juegoilegal' element={<JuegoIlegal />} /> */}
                <Route path='/noticias' element={<Noticias />} />
                <Route path='/institucionalMision' element={<InstitucionalMision />} />
                <Route path='/mision' element={<InstitucionalMision />} />
                <Route path='/autoridades' element={<InstitucionalAutoridades />} />
                <Route path='/delegaciones' element={<InstitucionalDelegaciones />} />
                <Route path='/agencias' element={<Agencias />} />
                <Route path='/dondejuego' element={<DondeJuego />} />
                <Route path='/lavadodeactivos' element={<Lavadodeactivos />} />
                <Route path='/certificados' element={<Certificados />} />
                <Route path='/diplomatura' element={<Diplomatura />} />
                <Route path='/videounamas' element={<Videounamas />} />
                <Route path='/formulario' element={<Formularios />} />
                <Route path='/formauto' element={<FormularioAuto />} />
                <Route path='/comotrabajamos' element={<ComoTrabajamos />} />
                <Route path='/quetaljuego' element={<Quetaljuegoyo />} />
                <Route path='/sitiosautorizados' element={<SitiosAutorizados />} />
                <Route path='/mitosyverdades' element={<Mitosyverdades />} />
                <Route path='/recomendaciones' element={<Recomendaciones />} />
                <Route path='/reglamentos' element={<Reglamentos />} />
                <Route path='/lugaresdeayuda' element={<LugaresdeAyuda />} />
                <Route path='/enconstruccion' element={<Enconstruccion />} />
                <Route path='/register' element={<Register />} />
                <Route path='/login' element={<Login />} />
                <Route path='/reset' element={<Reset />} />
                <Route path='/noganadores' element={<NoGanadores />} />
                <Route path='/verganador' element={<VerGanador />} />
                <Route path='/profile' element={<ProfileForm />} />
                <Route path='/comocargarcupon' element={<ComoCargarCupon />} />
                <Route path='/cuandosortea' element={<CuandoSortea />} />
                <Route path='/premios' element={<Premios />} />
                <Route path='/quienespueden' element={<QuienesPuedenParticipar />} />
                <Route path='/juegoilegal' element={<JuegoIlegal />} />
                <Route path='/denuncias' element={<Denuncias />} />
                <Route path='/ganadoresum' element={<Ganadoresum />} />
                <Route path='/formularioparticipacion' element={<Concurso />} />
                <Route path='/galeriafotos' element={<GaleriaFotos />} />
                <Route path='/cargarslider' element={<CargarSlider />} />
                <Route path='/BDusuarios' element={<BDusuarios />} />
                <Route path='/cupones/:id/:ns' element={<Cupones />} />
                <Route path='/ranking' element={<RankingCupones />} />
                <Route path='/rankingmes/:nsorteo' element={<RankingCuponesMes />} />
                <Route path='/cargarnoticia' element={<CargarNoticia />} />
                <Route path='/noti/:id' element={<NoticiaDetail />} />
                <Route path='/descargas' element={<Descargas />} />
                <Route path='/calendariojuegos' element={<Calendariodejuegos />} />
                <Route path='/AutoexcluidosLookup' element={<AutoexcluidosLookup />} />
                <Route path='/AutoexcluidosGallery' element={<AutoexcluidosGallery />} />
                <Route path="/ModificarAutoexcluido/:dni" element={<ModificarAutoexcluido />} />
                <Route path='/AddSorteo' element={<AddSorteoForm />} />
                <Route path='/Dashboard' element={<Dashboard />} />
                <Route path='/BDEmpleados' element={<BDEmpleados />} />
                <Route path='/BDAutoexcluidos' element={<BDAutoexcluidos />} />
                <Route path='/InformeAsistencia' element={<InformeAistencia />} />
                <Route path='/ConcursoRespEditForm/:id' element={<ConcursoRespEditForm />} />
                <Route path='/UserDocumentsGrid' element={<UserDocumentsGrid />} />
                <Route path='/YoutubeViewer/:id' element={<YoutubeViewer />} />
                <Route path='/ConcursoResppanel' element={<ConcursoResppanel />} />
                <Route path='/ConcursoResp' element={<ConcursoResp />} />
                <Route path='/bases' element={<Bases/>}/>
                <Route path='/RecomendacionesJuegoIlegal' element={<RecomendacionesJuegoIlegal/>}/>
                <Route path='/manualprocedimientos' element={<ManualProcedimientos/>}/>
                <Route path='/personasexpuestas' element={<PersonasExpuestas/>}/>
                <Route path='/porquedelaprevencion' element={<PorqueDeLaPrevencion/>}/>
                <Route path='/queesgafi' element={<QueEsGafi/>}/>
                <Route path='/normativacomplementaria' element={<NormativaComplementaria/>}/> 
                <Route path='/guiacobro' element={<GuiaCobro/>}/> 

              </Routes>
            </div>
            <Footer />
          </Router>
        </Wrapper>
      </div>
    </AuthProvider>
  );
}

export default App;