import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Button } from 'primereact/button';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

import Logout from '../Logout';
import Administracion from './Administracion';
import SearchRolesByEmail from './SearchRolesByEmail';
import ConcursoResppanel from './DashConcurso';
import AutoexcluidosPanel from './AutoexcluidosPanel';

const Dashboard = () => {
    const auth = useAuth();
    const [selectedOption, setSelectedOption] = useState('perfilUsuario');
    const [userRole, setUserRole] = useState(null);
    const [visibleSidebar, setVisibleSidebar] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Detectar tamaño de pantalla
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(
                    `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
                );
                setUserRole(response.data.role);
            } catch (error) {
                console.error("Error al obtener el rol del usuario:", error);
            }
        };

        fetchUserRole();
    }, [auth.id]);

    const logoutButton = () => {
        auth.logout();
    };

    const buildMenuItems = () => {
        const items = [];

        if (userRole === 'admin') {
            items.push(
                { label: 'Panel de Administración', icon: 'pi pi-cog', command: () => setSelectedOption('otraOpcion') },
                { label: 'Panel de Autoexcluidos', icon: 'pi pi-ban', command: () => setSelectedOption('auto') },
                { label: 'Usuarios y Roles', icon: 'pi pi-users', command: () => setSelectedOption('roles') },
                { label: 'Jugá por Jugar', icon: 'pi pi-video', command: () => setSelectedOption('concurso') }
            );
        }

        if (userRole === 'adminauto') {
            items.push(
                { label: 'Concurso Juego Responsable', icon: 'pi pi-star', command: () => setSelectedOption('concurso') },
                { label: 'Panel de Autoexcluidos', icon: 'pi pi-ban', command: () => setSelectedOption('auto') }
            );
        }

        if (userRole === 'agente') {
            items.push({ label: 'Panel de Autoexcluidos', icon: 'pi pi-ban', command: () => setSelectedOption('auto') });
        }

        if (userRole === 'escuela' || userRole === 'jurado') {
            items.push({ label: 'Jugá por Jugar', icon: 'pi pi-play', command: () => setSelectedOption('concurso') });
        }

        items.push(
            { label: 'Perfil de Usuario', icon: 'pi pi-user', command: () => setSelectedOption('perfilUsuario') },
            { label: 'Salir', icon: 'pi pi-sign-out', command: logoutButton }
        );

        return items;
    };

    return (
        <div className="p-d-flex p-flex-column" style={{ minHeight: '100vh' }}>
            {isMobile ? (
                <>
                    <div className="p-m-2">
                        <Button icon="pi pi-bars" onClick={() => setVisibleSidebar(true)} className="p-button-text" />
                    </div>
                    <Sidebar visible={visibleSidebar} onHide={() => setVisibleSidebar(false)} showCloseIcon>
                        <h3>Dashboard</h3>
                        <PanelMenu model={buildMenuItems()} style={{ width: '100%' }} />
                    </Sidebar>
                </>
            ) : (
                <div style={{ width: '250px', float: 'left', padding: '20px' }}>
                    <PanelMenu model={buildMenuItems()} style={{ width: '100%' }} />
                </div>
            )}

            <div
                style={{
                    padding: '20px',
                    marginLeft: isMobile ? '0' : '250px'
                }}
            >
                {selectedOption === 'perfilUsuario' && <Logout />}
                {selectedOption === 'otraOpcion' && <Administracion />}
                {selectedOption === 'roles' && <SearchRolesByEmail />}
                {selectedOption === 'concurso' && <ConcursoResppanel />}
                {selectedOption === 'auto' && <AutoexcluidosPanel />}
            </div>
        </div>
    );
};

export default Dashboard;
