import React, { useState } from 'react';
import { subirFotoAuto, subirPdfAuto, db } from "../../firebase/firebase.config";
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import enviarCorreo from "../../firebase/enviarCorreo";


function FormularioAutoexclusion() {
  const [nombre, setNombre] = useState('');
  const [dni, setDni] = useState('');
  const [domicilio, setDomicilio] = useState('');
  const [nacionalidad, setNacionalidad] = useState('');
  const [telefono, setTelefono] = useState('');
  const [mail, setMail] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [dniPdf, setDniPdf] = useState(null);
  const [files, setFiles] = useState([null, null]);
  const [declaracion1, setDeclaracion1] = useState(false);
  const [declaracion11, setDeclaracion11] = useState(false);
  const [declaracion2, setDeclaracion2] = useState(false);
  const [declaracion3, setDeclaracion3] = useState(false);
  const [declaracion4, setDeclaracion4] = useState(false);
  const [declaracion5, setDeclaracion5] = useState(false);
  const [alta, setAlta] = useState(new Date().toISOString().split('T')[0]);
  const [telefonoApoyo, setTelefonoApoyo] = useState('');
  const [ocupacion, setOcupacion] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [nombrePersonaApoyo, setNombrePersonaApoyo] = useState('');
  const [observaciones, setObservaciones] = useState([]);
  const [observacionInput, setObservacionInput] = useState('');
  const [lugarEntrega, setLugarEntrega] = useState('');


  const enviarCorreos = async (listaCorreos, asunto, texto) => {
    const promesasEnvio = listaCorreos.map(correo =>
      enviarCorreo(correo, asunto, texto)
        .then(() => console.log('Correo enviado correctamente a:', correo))
        .catch(error => {
          console.error('Error al enviar correo a:', correo, error);
          alert('Error al enviar correo a: ' + correo);
        })
    );

    await Promise.all(promesasEnvio);
  };

  const formatFechaHora = (marcaDeTiempo) => {
    const fecha = new Date(marcaDeTiempo);
    const opciones = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC", // Establecer la zona horaria a UTC
    };
    return new Intl.DateTimeFormat("es-AR", opciones).format(fecha);
  };
  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    setPdfFile(file);
  };

  const handleDniPdfChange = (e) => {
    const file = e.target.files[0];
    setDniPdf(file);
  };

  const getFileType = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const arr = new Uint8Array(reader.result).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        resolve(getMimeType(header));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const getMimeType = (header) => {
    switch (header) {
      case '89504e47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
        return 'image/jpeg';
      case '504b0304':
        return 'application/zip';
      default:
        return 'unknown';
    }
  };

  const handleFileChange = async (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];

    // Verifica que la foto actualizada sea cuadrada
    if (newFiles[index]) {
      const image = new Image();
      image.src = URL.createObjectURL(newFiles[index]);

      image.onload = async () => {
        if (image.width !== image.height) {
          alert('La foto actualizada debe ser cuadrada. Por favor, selecciona una imagen cuadrada.');
          // Puedes restablecer la entrada del archivo a null o realizar otras acciones según tus necesidades.
          newFiles[index] = null;
          setFiles(newFiles);
          return;
        }

        // Verifica que el tamaño del archivo sea menor a 100 KB
        const fileSize = newFiles[index].size / 1024; // Convertir a KB
        if (fileSize > 100) {
          alert('El tamaño de la imagen debe ser menor a 100 KB. Por favor, selecciona una imagen más pequeña.');
          // Puedes restablecer la entrada del archivo a null o realizar otras acciones según tus necesidades.
          newFiles[index] = null;
          setFiles(newFiles);
        }
      };
    }

    setFiles(newFiles);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();


    const dniExistQuery = query(collection(db, 'autoexcluidos'), where('dni', '==', dni));
    const dniExistSnapshot = await getDocs(dniExistQuery);

    if (!dniExistSnapshot.empty) {
      alert('Ya existe un autoexcluido con este DNI en la base de datos.');
      return;
    }

    const validSize = 250 * 1024; // 250KB
    const areFilesValid = await Promise.all(
      files.map(async (file) => {
        if (!file) {
          return true;
        }

        const fileType = await getFileType(file);
        if (fileType !== 'image/jpeg' || file.size > validSize) {
          return false;
        }
        return true;
      })
    );

    if (!areFilesValid.every((valid) => valid)) {
      const invalidFiles = files.filter(
        (file, index) =>
          file &&
          (areFilesValid[index] === false ||
            file.type !== 'image/jpeg' ||
            file.size > validSize)
      );
      const invalidFileDetails = invalidFiles.map((file) => ({
        name: file ? file.name : 'Archivo inválido',
        type: file ? getFileExtension(file.name) : 'Desconocido',
      }));

      const errorMessage = `Los siguientes archivos no son válidos:\n${invalidFileDetails
        .map((file) => `${file.name} (${file.type})`)
        .join('\n')}\n\nDeben ser de formato JPG y tener un tamaño menor a 250KB`;
      alert(errorMessage);
      return;
    }

    try {
      const fotosUrls = await Promise.all(files.map(file => (file ? subirFotoAuto(file) : null)));

      let pdfUrl = null;
      if (pdfFile) {
        const validSize = 500 * 1024; // 500KB

        if (pdfFile.size > validSize) {
          alert('El archivo PDF debe tener un tamaño menor a 500KB');
          return;
        }

        pdfUrl = await subirPdfAuto(pdfFile);
      }

      const pdfUrls = pdfUrl ? [{ nombredoc: 'PDF del Alta', url: pdfUrl }] : [];
      let pdfDniUrl = null;
      if (dniPdf) {
        const validSize = 500 * 1024; // 500KB

        if (dniPdf.size > validSize) {
          alert('El archivo PDF debe tener un tamaño menor a 500KB');
          return;
        }

        pdfDniUrl = await subirPdfAuto(dniPdf);
      }




      const fechaAlta = new Date(alta);
      const fechaVencimiento = new Date(fechaAlta.getFullYear() + 2, fechaAlta.getMonth(), fechaAlta.getDate());

      const formData = {
        idd: uuidv4(),
        nombre,
        dni,
        domicilio,
        nacionalidad,
        telefono,
        telefonoApoyo,
        mail,
        lugarEntrega,
        declaracion11,
        declaracion1,
        declaracion2,
        declaracion3, 
        declaracion4,
        declaracion5,
        pdfDocument: [
          { nombredoc: 'PDF del Alta', url: pdfUrl },
          { nombredoc: 'PDF del DNI', url: pdfDniUrl }
        ],
        ocupacion,
        localidad,
        fechaNacimiento,
        nombrePersonaApoyo,
        activo: true,
        alta,
        vencimiento: fechaVencimiento.toISOString().split('T')[0],
        fotos: fotosUrls,
        observaciones: [...observaciones, { fecha: alta, observacion: observacionInput }],
        cambios: [
          {
            tipo: 'Alta',
            marcaDeTiempo: fechaAlta.toISOString(),
          },
        ],
      };

      await addDoc(collection(db, 'autoexcluidos'), formData);

      alert('Los datos se han cargado correctamente');

      const fechaAlta1 = new Date(alta);
      const fechaVencimiento1 = new Date(formData.vencimiento);

      const texto = `
    <h3 style="text-align: justify;">A La empresa:<br /><br />SU DESPACHO:<br /><br />Tengo el agrado de dirigirme a Usted, con el objeto de comunicarle que el día ${formatFechaHora(alta).split(',')[0]}, el SRA./SR ${nombre} , DNI ${dni}, ha procedido a firmar voluntariamente el formulario de AUTOEXCLUSI&Oacute;N, en las instalaciones de ${lugarEntrega} por lo que se informa que el mismo tendrá una vigencia de DOS (2) AÑOS, que se renovará de manera automática, en caso de no presentar en&nbsp;Nuestra Institución, Sito en Av. Italia N&ordm;39 &ndash; de esta Ciudad, el Acta de Revocación, acompa&ntilde;ada del debido Certificado médico de alta del tratamiento correspondiente.</h3>
    <h3 style="text-align: justify;"><br />Por todo ello, se comunica a Usted que deber&aacute; impedir el ingreso de SRA./SR ${nombre} a todas las Salas de Juego físicas u online de la Provincia de Jujuy, desde el ${formatFechaHora(fechaAlta1).split(',')[0]} y por el periodo de DOS (2) A&Ntilde;OS hasta el ${formatFechaHora(fechaVencimiento1).split(',')[0]}, todo ello según lo dispuesto por Resolución N&ordm; 047-VP-2022- dispuesta por el Instituto Provincial de Juegos de Azar de Jujuy (InProJuy), el cual dispone el procedimiento de AUTOEXCLUSIONES.</h3>
    <h3>Sin otro particular saludo a Usted, muy atentamente.</h3>
    `;
      //enviarCorreo('ricardoperezlindo@gmail.com; glamas@inprojuy.gob.ar; dgarcia@inprojuy.gob.ar; mariaangelesspengler@gmail.com', 'Alta Autoexcluido', texto).then(res => {
      /*      enviarCorreo('ricardoperezlindo@gmail.com', 'Alta Autoexcluido', texto).then(res => {
   
         alert("mail enviado")
           console.log(res)
         }) */


      fetch('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/automails')
        .then(response => response.json())
        .then(data => {
          const listaCorreos = data.map(item => item.email);
          console.log('Lista de correos:', listaCorreos);

          return enviarCorreos(listaCorreos, 'Alta Autoexcluido', texto);
        })
        .then(() => {
          console.log('Todos los correos enviados correctamente');
          window.location.reload(); // Recargar la página después de enviar los correos electrónicos
        })
        .catch(error => console.error('Error:', error));

    } catch (error) {
      console.error(error);
      alert('Error al cargar los datos. Inténtalo de nuevo.');
    }
  };


  function getFileExtension(fileName) {
    return fileName.split('.').pop().toLowerCase();
  }


  return (
    <form className="contactform row g-3 mt-4" onSubmit={handleSubmit}>
      <div>

        <div>
          <label className="h5 form-label">
            Nombre:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="h5 form-label">
            DNI:
          </label>
        </div>
        <div>
          <input
            type="number"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="dni"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            required
          />
        </div>
        <div className="input-group col-md-12 m-0">
          <label className="h5 col-md-12 form-label">Foto Actualizada (n X n pixeles, cuadrada hasta 250KB)  </label>
          <input required type='file' className="form-control my-3 border px-3 py-2 rounded placeholder-gray-500" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" onChange={(e) => handleFileChange(e, 0)} />
        </div>
        <div>
          <label className="h5 form-label">
            Domicilio:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="domicilio"
            value={domicilio}
            onChange={(e) => setDomicilio(e.target.value)}

          />
        </div>
        <div>
          <label className="h5 form-label">
            Ocupación:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="ocupacion"
            value={ocupacion}
            onChange={(e) => setOcupacion(e.target.value)}

          />
        </div>

        <div>
          <label className="h5 form-label">
            Localidad:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="localidad"
            value={localidad}
            onChange={(e) => setLocalidad(e.target.value)}

          />
        </div>

        <div className="mb-3">
          <label htmlFor="fechaNacimiento" className="form-label h5">
            Fecha de Nacimiento
          </label>
          <input
            type="date"
            className="form-control"
            id="fechaNacimiento"
            value={fechaNacimiento}
            onChange={(e) => setFechaNacimiento(e.target.value)}

          />
        </div>


        <div>
          <label className="h5 form-label">
            Nacionalidad:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="nacionalidad"
            value={nacionalidad}
            onChange={(e) => setNacionalidad(e.target.value)}

          />
        </div>
        <div>
          <label className="h5 form-label">
            Teléfono:
          </label>
        </div>
        <div>
          <input type="tel" className="form-control border px-3 py-2 rounded placeholder-gray-500" name="telefono" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
        </div>


        <div>
          <label className="h5 form-label">
            Nombre de Persona de Apoyo:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="nombrePersonaApoyo"
            value={nombrePersonaApoyo}
            onChange={(e) => setNombrePersonaApoyo(e.target.value)}

          />
        </div>
        <div>
          <label className="h5 form-label">
            Teléfono de Apoyo:
          </label>
        </div>
        <div>
          <input
            type="tel"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="telefonoApoyo"
            value={telefonoApoyo}
            onChange={(e) => setTelefonoApoyo(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="h5 form-label">
            E-mail:
          </label>
        </div>
        <div>
          <input required type="email" className="form-control border px-3 py-2 rounded placeholder-gray-500" id="exampleInputEmail1" aria-describedby="emailHelp" name="mail" value={mail} onChange={(e) => setMail(e.target.value)} />
        </div>

        <div className="mb-3">
          <label htmlFor="alta" className="form-label h5">
            Fecha de Alta
          </label>
          <input
            type="date"
            className="form-control"
            id="alta"
            value={alta}
            onChange={(e) => setAlta(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="h5 form-label">
            Lugar de Entrega:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="lugarEntrega"
            value={lugarEntrega}
            onChange={(e) => setLugarEntrega(e.target.value)}
            required
          />
        </div>


        <div>
          <label className="h5 col-md-12 form-label">Documento PDF (hasta 500KB)</label>
          <input required type="file" className="form-control my-3 border px-3 py-2 rounded placeholder-gray-500" onChange={handlePdfChange} />
        </div>
        <div>
          <label className="h5 col-md-12 form-label">Documento PDF del DNI (hasta 500KB)</label>
          <input type="file" className="form-control my-3 border px-3 py-2 rounded placeholder-gray-500" onChange={handleDniPdfChange} />
        </div>



        <div>
          <label className="h5 form-label">
            Observación:
          </label>
        </div>
        <div>
          <input
            type="text"
            className="form-control border px-3 py-2 rounded placeholder-gray-500"
            name="observacion"
            value={observacionInput}
            onChange={(e) => setObservacionInput(e.target.value)}
            maxLength={500}
          />
        </div>

        <div className="h5 form-label p-4">
          {/*   <div>
            <label className="h5 form-label">
              <input
                type="checkbox"
                checked={declaracion1}
                onChange={() => setDeclaracion1(!declaracion1)}
              />
               - NO ingresaré a ninguna Sala de Juego de la provincia de Jujuy, Casinos, Bingos.
            </label>
          </div>
          <div>
            <label className="h5 form-label">
              <input
                type="checkbox"
                checked={declaracion11}
                onChange={() => setDeclaracion11(!declaracion11)}
              />
               - Me autoexcluyo de juegos lotericos y equipos de video lotería ubicados en las agencias de Tómbola.
            </label>
          </div>
          <div>
            <label className="h5 form-label">
              <input
                type="checkbox"
                checked={declaracion2}
                onChange={() => setDeclaracion2(!declaracion2)}
              />
              - Que la presente solicitud la otorgo con carácter irrevocable hasta el plazo de 2 años.
            </label>
          </div>
          <div>
            <label className="h5 form-label">
              <input
                type="checkbox"
                checked={declaracion3}
                onChange={() => setDeclaracion3(!declaracion3)}
              />
              - Declaro haber recibido la charla informativa sobre Juego Responsable.
            </label>
          </div>
          <div>
            <label className="h5 form-label">
              <input
                type="checkbox"
                checked={declaracion4}
                onChange={() => setDeclaracion4(!declaracion4)}
              />
              - El ingreso al presente programa es voluntario, responsabilizándome exclusivamente de su cumplimiento.
            </label>
          </div>
          <div>
            <label className="h5 form-label">
              <input
                type="checkbox"
                checked={declaracion5}
                onChange={() => setDeclaracion5(!declaracion5)}
              />
              - Comprendo y consiento que ni Inprojuy, ni la Secretaría de Salud Mental pueden garantizar totalmente el cumplimiento del presente.
            </label>
          </div> */}

        </div>

        <div>
          <button className="btn btn-primary mt-4 text-white" type="submit">Enviar</button>
        
        </div>
      </div>
    </form>
  );
}

export default FormularioAutoexclusion;
