import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';

function InformeAsistencia() {
  const columns = [
    { field: 'autoexcluido', headerName: 'Nombre', flex: 2 },
    { field: 'fecha', headerName: 'Fecha', flex: 1, valueGetter: (params) => formatDate(params.value) },
    { field: 'usuario_denunciante', headerName: 'Usuario Denunciante', flex: 1 },
    { field: 'sala', headerName: 'Sala', flex: 1 },
    { field: 'direccion', headerName: 'Dirección', flex: 1 },
  ];

  const [informe, setInforme] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/cambios-asistencia');
        const dataWithIds = response.data.map((item, index) => ({ ...item, id: index }));
        setInforme(dataWithIds);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    return date.toLocaleString('es-AR', {
      timeZone: 'America/Argentina/Buenos_Aires',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(informe);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'InformeAsistencia');
    XLSX.writeFile(wb, 'informe_asistencia.xlsx');
  };

  return (
    <div className="container-fluid py-5 ">
      <Container style={{ height: '100%', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <h2>Intentos de asistencia a salas reportados</h2>
          <Button onClick={exportToExcel} disabled={loading}>Exportar a Excel</Button>
        </div>
        <p> </p>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={informe}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            loading={loading}
          />
        </div>
      </Container>
    </div>
  );
}

export default InformeAsistencia;
