import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { TabView, TabPanel } from 'primereact/tabview';
import AutoexcluidosGallery from './../../components/Formularios/AutoexcluidosGallery';
import AutoexcluidosLookup from './../../components/Formularios/AutoexcluidosLookup';
import InformeAsistencia from './../../components/Formularios/InformeAsistencia';
import FormularioAuto from './../../components/Formularios/FormularioAuto';
import BDEmpleados from './../../components/dashboard/BDEmpleados';
import BDAutoexcluidos from './../../components/dashboard/BDAutoexcluidos';

const AutoexcluidosPanel = () => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
        );
        setUserRole(response.data.role);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener el rol del usuario:", error);
        setLoading(false);
      }
    };

    fetchUserRole();
  }, [auth.id]);

  if (loading) return <div>Cargando...</div>;

  return (
    <div id="panelAuto" style={{
      border: '1px solid #000',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '10px',
      borderRadius: '5px',
      width: '100%',
      maxWidth: '1000px',
      margin: 'auto'
    }}>
      <center>
        <h1 className="botonbordeazul text-white px-5 mx-5 py-3 h1">Panel de Autoexcluidos</h1>
      </center>

      <TabView style={{ width: '100%' }}>
        <TabPanel header="Galería de Autoexcluidos">
          <div style={{ padding: '10px' }}>
            <AutoexcluidosGallery />
           
          </div>
        </TabPanel>

        <TabPanel header="Buscar Autoexcluidos">
          <div style={{ padding: '10px' }}>
           <AutoexcluidosLookup />
          </div>
        </TabPanel>

        {(userRole === "admin" || userRole === "adminauto") && (
          <TabPanel header="Informe de Asistencia">
            <div style={{ padding: '10px' }}>
              <InformeAsistencia />
            </div>
          </TabPanel>
        )}

        {(userRole === "admin" || userRole === "adminauto") && (
          <TabPanel header="Alta de Autoexcluidos">
            <div style={{ padding: '10px' }}>
              <FormularioAuto />
            </div>
          </TabPanel>
        )}

        <TabPanel header="Ver Empleados">
          <div style={{ padding: '10px' }}>
            <BDEmpleados />
          </div>
        </TabPanel>

        <TabPanel header="BD Autoexcluidos">
          <div style={{ padding: '10px' }}>
           <BDAutoexcluidos />
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default AutoexcluidosPanel;
