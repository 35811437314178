import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from "../../context/AuthContext";
import ProfileModal from './ProfileModal';
import { Link } from 'react-router-dom'; 




function AutoexcluidosGallery() {
  const [autoexcluidos, setAutoexcluidos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [photosPerPage] = useState(8);

  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [modalCerrado, setModalCerrado] = useState(true);

  const handleShowProfileModal = () => {
    setShowProfileModal(true);
  };

  const [showProfileModal, setShowProfileModal] = useState(false);

  const auth = useAuth();
  const [autoexcluidoData, setAutoexcluidoData] = useState(null);
  const [modificacionData, setModificacionData] = useState({
    nombre: '',
    dni: '',
    domicilio: '',
    nacionalidad: '',
    telefono: '',
    mail: '',
    declaracion1: false,
    declaracion11: false,
    declaracion2: false,
    declaracion3: false,
    declaracion4: false,
    declaracion5: false,
    cambios: [],
    fotos: [],
    activo: false,
    //vencimiento: '',  // Nuevo campo vencimiento
    telefonoApoyo: '',  // Nuevo campo telefonoApoyo
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [selectedUserData, setSelectedUserData] = useState(null);

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/autoexcluidos`
        );
        setAutoexcluidos(response.data);
      } catch (error) {
        console.error("Error fetching autoexcluidos data", error);
      }
    };

    fetchData();
  }, []);

  // Paginación
  const indexOfLastPhoto = currentPage * photosPerPage;
  const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
  const currentPhotos = autoexcluidos.slice(indexOfFirstPhoto, indexOfLastPhoto);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const registerChange = async (tipo) => {
    try {
      // Verifica que haya un usuario autenticado y autoexcluidoData no sea null
      if (auth.user && autoexcluidoData && autoexcluidoData.id) {
        const cambio = {
          tipo,
          marcaDeTiempo: new Date().toISOString(),
          usuario: auth.user,
        };

        // Crea una copia del array actual de cambios y agrega el nuevo cambio
        const cambios = [...autoexcluidoData.cambios, cambio];

        // Envia el cambio al servidor y actualiza el estado local después del éxito
        await axios.post(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/modiautoexcluidos/${autoexcluidoData.id}`, {
          cambios: cambios,
          // Resto de los datos que necesitas enviar
        });

        // Actualiza el estado local después del éxito del servidor
        setAutoexcluidoData((prevData) => ({
          ...prevData,
          cambios: cambios,
        }));

        

        // Indica que el registro fue exitoso
        setRegistroExitoso(true); 
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error("Error registrando intento", error);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUserData(null);

    // Indica que el modal ha sido cerrado
    setModalCerrado(true);
  };

  const handleCerrarModal = () => {
    // Cierra el modal manualmente
    setShowModal(false);
    setModalCerrado(true);
  };
  
  
  

  const handleShow = async (photoUrl, dni) => {
    setSelectedPhoto(photoUrl);
    setShowModal(true);
  
    try {
      const userDataResponse = await axios.get(
        `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/autoexcluidos/${dni}`
      );
      setSelectedUserData(userDataResponse.data);
  
      // Establecer el estado autoexcluidoData
      setAutoexcluidoData(userDataResponse.data);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };
  


  const getColSize = () => {
    if (isDesktopOrLaptop) return 3;
    if (isTabletOrMobile) return 4;
    return 4; // Default value for other cases
  };

  return (
    <div>
      <Container className="mt-4">
        <Row>
          {currentPhotos.map((autoexcluido) => (
          <Col key={autoexcluido.dni} xs={12} md={getColSize()} className="mb-4">
          <div className="photo-container">
            <div className="caption">
              <p className="caption-text"> {autoexcluido.nombre}</p>
            </div>
            <img
              src={autoexcluido.fotos.filter((foto) => foto !== null).pop() || ""}

              alt={`Autoexcluido ${autoexcluido.dni}`}
              className="img-fluid"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              onClick={() => handleShow(autoexcluido.fotos.filter((foto) => foto !== null).pop() || "", autoexcluido.dni)}
            />
          </div>
        </Col>
          ))}
        </Row>
        <Pagination
          photosPerPage={photosPerPage}
          totalPhotos={autoexcluidos.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </Container>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedPhoto}
            alt="Foto"
            className="img-fluid"
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
          {selectedUserData && (
            <div>
              <h5>DNI: {selectedUserData.dni}</h5>
              <h5>Nombre: {selectedUserData.nombre}</h5>
              <h5>Alta: {selectedUserData.alta}</h5>
              <button onClick={() => registerChange("Asistencia a lugar de juego")}>
                Registrar Asistencia a Agencia o Sala de Juego
              </button>
              <button onClick={handleShowProfileModal}>Ver Perfil Completo</button>
            
            </div>
          )}
          {registroExitoso && (
            <p style={{ color: "green" }}>¡Registro exitoso! Se ha registrado la asistencia.</p>
          )}
        
        </Modal.Body>
      </Modal>
      <ProfileModal show={showProfileModal} handleClose={() => setShowProfileModal(false)} dni={selectedUserData && selectedUserData.dni} />

    </div>
  );
}

const Pagination = ({ photosPerPage, totalPhotos, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPhotos / photosPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className={number === currentPage ? 'page-item active' : 'page-item'}>
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
     
    </nav>
  );
};

export default AutoexcluidosGallery;
