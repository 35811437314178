import React from "react";
import Form from "../components/Form";
import { useAuth } from "../context/AuthContext";
import Logout from "../components/Logout";
import Dashboard from "../components/dashboard/Dasboard";


function Login() {
  const auth = useAuth();
  return (
   
<div style={{ display: 'block', width: '100%', minHeight: '100vh' }}>
  {auth.user.length > 0 ? <Dashboard /> : <Form nameForm="Ingresar" />}
</div>



  );
}

export default Login;